import {Expense} from 'types/models/Expense';

export const GET_EXPENSE_TO_TRANSMIT_LIST = 'GET_EXPENSE_TO_TRANSMIT_LIST';

export const SEND_EXPENSE_TO_TRANSMIT_LIST = 'SEND_EXPENSE_TO_TRANSMIT_LIST';

export const GET_EXPENSE_TO_PAY_LIST = 'GET_EXPENSE_TO_PAY_LIST';

export const CHECKED_ALL = 'CHECKED_ALL';

export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';

export interface CheckedAll {
    type: typeof CHECKED_ALL;
    checked: boolean;
}

export interface UpdateExpense {
    type: typeof UPDATE_EXPENSE;
    updated: Expense;
}

export interface GetExpensesToTransmitListActions {
    type: typeof GET_EXPENSE_TO_TRANSMIT_LIST;
    payload: {
        expenses: Expense[];
        sendTo: string;
        total?: number;
        effectiveDate: Date | null;
    };
}

export interface GetExpensesToPayListActions {
    type: typeof GET_EXPENSE_TO_PAY_LIST;
    payload: {
        expenses: Expense[];
    };
}

export type ExpenseActions = GetExpensesToTransmitListActions | GetExpensesToPayListActions | CheckedAll | UpdateExpense;