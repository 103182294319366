import React from 'react';

export const expensesPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/expenses/to-transmit',
        component: React.lazy(() => import('./Pages/ToTransmit')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/expenses/to-pay',
        component: React.lazy(() => import('./Pages/ToPay')),
      },
    ],
  },
];
