import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const AppLogo = () => {
    const useStyles = makeStyles(() => ({
        logoRoot: {
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            alignItems: 'center',
        },
        logo: {
            height: 65,
            marginRight: 10,
        },
    }));
    const classes = useStyles();
    return (
            <Box className={classes.logoRoot}>
                <Hidden smUp>
                    <img
                            className={classes.logo}
                            src='/assets/images2/logo.svg'
                            alt='Open Web Technology'
                    />
                </Hidden>
                <Hidden xsDown>
                    <img
                            className={classes.logo}
                            src='/assets/images2/logo.svg'
                            alt='Open Web Technology'
                    />
                </Hidden>
            </Box>
    );
};

export default AppLogo;
