import {AppActions} from '../../types';
import { SIGNOUT_AUTH_SUCCESS, UPDATE_AUTH_USER,} from '../../types/actions/Auth.actions';
import {AuthUser} from '../../types/models/AuthUser';

const INIT_STATE: { user: AuthUser | null;} = {
    user: null,
};

const Auth = (state = INIT_STATE, action: AppActions) => {
    switch (action.type) {
        case UPDATE_AUTH_USER: {
            console.dir(action.payload);
            return {
                ...state,
                user: action.payload,
            };
        }
        case SIGNOUT_AUTH_SUCCESS: {
            return {
                ...state,
                user: null,
            };
        }
        default:
            return state;
    }
};
export default Auth;
