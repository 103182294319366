import {AppActions} from 'types';
import {
    CHECKED_ALL,
    GET_EXPENSE_TO_PAY_LIST,
    GET_EXPENSE_TO_TRANSMIT_LIST,
    UPDATE_EXPENSE
} from 'types/actions/Expense.actions';
import {Expense} from '../../types/models/Expense';

export interface ExpenseListState {
    expenses_to_transmit: Expense[],
    expenses_to_pay: Expense[],
    sendTo: string,
    total?: number,
    effectiveDate: Date | null
}
const initialState: ExpenseListState = {
    expenses_to_transmit: [],
    expenses_to_pay: [],
    sendTo: '',
    total: 0,
    effectiveDate: null,
}
const ExpenseList = (state = initialState, action: AppActions) => {
    switch (action.type) {
        case UPDATE_EXPENSE: {
            let index = state.expenses_to_transmit.indexOf(action.updated);
            state.expenses_to_transmit[index] = action.updated;
            return {
                ...state,
            };
        }
        case CHECKED_ALL: {
            state.expenses_to_transmit.forEach(value => value.active = action.checked)
            return {
                ...state,
            };
        }
        case GET_EXPENSE_TO_TRANSMIT_LIST: {
            return {
                ...state,
                expenses_to_transmit: action.payload.expenses,
                sendTo: action.payload.sendTo,
                total: action.payload.total,
                effectiveDate: action.payload.effectiveDate,
            };
        }
        case GET_EXPENSE_TO_PAY_LIST: {
            return {
                ...state,
                expenses_to_pay: action.payload,
            };
        }
        default:
            return state;
    }
};
export default ExpenseList;