import { Dispatch } from 'redux';

import Api from '@crema/services/ApiConfig';
import { appIntl } from '@crema/utility/Utils';

import { AppActions } from 'types';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions/Common';
import {
    CHECKED_ALL,
    GET_EXPENSE_TO_PAY_LIST,
    GET_EXPENSE_TO_TRANSMIT_LIST,
    UPDATE_EXPENSE
} from 'types/actions/Expense.actions';
import { Expense } from "../../types/models/Expense";

export const onGetToTransmitList = () => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        dispatch({type: GET_EXPENSE_TO_TRANSMIT_LIST, payload: {expenses: [], sendTo: '', effectiveDate: null, total: 0}});
        Api.get('/api/expenses/to-transmit')
                .then(data => {
                    if (data.status === 200) {
                        dispatch(fetchSuccess());
                        dispatch({type: GET_EXPENSE_TO_TRANSMIT_LIST, payload: data.data});
                    } else {
                        dispatch(
                                fetchError(messages['message.somethingWentWrong'] as string),
                        );
                    }
                })
                .catch(error => {
                    dispatch(fetchError(error.message));
                });
    };
};

export const onCheckedAll = (checked: boolean) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({type: CHECKED_ALL, checked: checked});
    };
};

export const onUpdateExpense = (updated: Expense) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({type: UPDATE_EXPENSE, updated: updated});
    };
};

export const onSendExpensesToTransmit = (expenses: Expense[], sendTo: String, effectiveDate: Date | null) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        Api.post('/api/expenses/to-transmit', {
            expenses: expenses,
            sendTo: sendTo,
            effectiveDate: effectiveDate
        })
                .then(data => {
                    if (data.status === 200) {
                        dispatch(fetchSuccess());
                        dispatch(showMessage(messages['totransmit.send-report-success'] as string));
                        dispatch({
                            type: GET_EXPENSE_TO_TRANSMIT_LIST,
                            payload: {expenses: [], sendTo: '', effectiveDate: null, total: 0}
                        });
                    } else {
                        dispatch(
                                fetchError(messages['message.somethingWentWrong'] as string)
                        );
                    }
                })
                .catch(error => {
                    dispatch(fetchError(error.message));
                });
    };
};
export const onSendExpensesToPay = (expenses: Expense[]) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        Api.post('/api/expenses/to-pay', expenses)
                .then(data => {
                    if (data.status === 200) {
                        dispatch(fetchSuccess());
                        dispatch(showMessage(messages['topay.send-report-success'] as string));
                        dispatch({type: GET_EXPENSE_TO_PAY_LIST, payload: {expenses: []}});
                    } else {
                        dispatch(
                                fetchError(messages['message.somethingWentWrong'] as string)
                        );
                    }
                })
                .catch(error => {
                    dispatch(fetchError(error.message));
                });
    };
};
export const onGetToPayList = () => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        dispatch({type: GET_EXPENSE_TO_PAY_LIST, payload: {expenses: []}});
        Api.get('/api/expenses/to-pay')
                .then(data => {
                    if (data.status === 200) {
                        dispatch(fetchSuccess());
                        console.dir(data);
                        dispatch({type: GET_EXPENSE_TO_PAY_LIST, payload: data.data});
                    } else {
                        dispatch(
                                fetchError(messages['message.somethingWentWrong'] as string),
                        );
                    }
                })
                .catch(error => {
                    dispatch(fetchError(error.message));
                });
    };
};