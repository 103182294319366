import axios from 'axios';

const jwtAxios = axios.create({
  baseURL: '/api/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

jwtAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
    }
    return Promise.reject(err);
  },
);

export default jwtAxios;
