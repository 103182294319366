import {AuthUser} from '../models/AuthUser';

export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const SIGNOUT_AUTH_SUCCESS = 'SIGNOUT_AUTH_SUCCESS';

export interface UpdateAuthUserActions {
  type: typeof UPDATE_AUTH_USER;
  payload: AuthUser | null;
}

export interface SignoutAuthUserActions {
  type: typeof SIGNOUT_AUTH_SUCCESS;
}

export type AuthActions =
  | UpdateAuthUserActions
  | SignoutAuthUserActions;
