import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {fetchStart, fetchSuccess} from 'redux/actions';
import {AppState} from 'redux/store';
import {AuthType} from 'shared/constants/AppEnums';
import {defaultUser} from 'shared/constants/AppConst';
import {UPDATE_AUTH_USER} from 'types/actions/Auth.actions';
import {AuthUser} from 'types/models/AuthUser';
import jwtAxios from '@crema/services/auth/basic-auth/basic-api';

export const useAuthToken = (): [boolean, AuthUser | null] => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

    useEffect(() => {
        const validateAuth = async () => {
            dispatch(fetchStart());
            try {
                const res = await jwtAxios.get('/users/me');
                dispatch(fetchSuccess());
                dispatch({
                    type: UPDATE_AUTH_USER,
                    payload: {
                        authType: AuthType.AUTH,
                        displayName: res.data.displayName,
                        account: res.data.account,
                        uid: res.data.uid,
                        role: defaultUser.role
                    },
                });
                return;
            } catch (err) {
                dispatch(fetchSuccess());
                return;
            }
        };

        const checkAuth = () => {
            Promise.all([validateAuth()]).then(() => {
                setLoading(false);
            });
        };
        checkAuth();
    }, [dispatch]);
    return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
    const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
    if (user) {
        return user;
    }
    return null;
};
