import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import ExpenseList from './Expense';

const reducers = {
    settings: Settings,
    auth: Auth,
    common: CommonReducer,
    expenseList: ExpenseList
};

export default reducers;
