import jwtAxios from '../../@crema/services/auth/basic-auth/basic-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {SIGNOUT_AUTH_SUCCESS, UPDATE_AUTH_USER,} from '../../types/actions/Auth.actions';


export const onSignIn = (username: string, password: string, account: string ) => {
    return async (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        const data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);
        data.append('account', account);
        try {
            const res = await jwtAxios.post('login', data.toString());
            console.dir(res);
            await loadUser(dispatch);
        } catch (err) {
            console.log('error:', err);
            dispatch(fetchError(err));
        }
    };
};

export const loadUser = async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
        const res = await jwtAxios.get('/users/me');
        dispatch(fetchSuccess());
        console.log('res.data', res.data);
        dispatch({
            type: UPDATE_AUTH_USER,
            payload: getUserObject(res.data),
        });
    } catch (err) {
        //console.log('error:', err.response.error);
        //dispatch(fetchError(err.response.error));
        console.log('error:', err);
        dispatch(fetchError(err));
    }
};

const getUserObject = (authUser: any): AuthUser => {
    return {
        authType: AuthType.AUTH,
        displayName: authUser.displayName,
        role: defaultUser.role,
        uid: authUser.uid,
        account: authUser.account,
    };
};

export const onSignout = () => {
    return async (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        try {
            await jwtAxios.get('logout');
            dispatch(fetchSuccess());
            dispatch({type: SIGNOUT_AUTH_SUCCESS});
        } catch (err) {
            console.log('error:', err.response.data.error);
            dispatch(fetchError(err.response.data.error));
        }
    };
};
