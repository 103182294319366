export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'expenses',
    title: 'Expenses',
    messageId: 'sidebar.expenses',
    type: 'collapse',
    icon: 'dashboard',
    children: [
      {
        id: 'expenses-to-transmit',
        title: 'To Transmit',
        messageId: 'sidebar.expenses.to-transmit',
        type: 'item',
        url: '/expenses/to-transmit',
      },
      {
        id: 'expenses-to-pay',
        title: 'To Pay',
        messageId: 'sidebar.expenses.to-pay',
        type: 'item',
        url: '/expenses/to-pay',
      },
    ],
  },
];
export default routesConfig;
