import React, {FC} from 'react';
import {useDispatch} from 'react-redux';

import {makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import {orange} from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';

import {onSignout} from 'redux/actions';
import {useAuthUser} from '@crema/utility/AppHooks';
import {Fonts} from 'shared/constants/AppEnums';

const useStyles = makeStyles(theme => {
    return {
        userRoot: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'center',
        },
        avatar: {
            fontSize: 24,
            backgroundColor: orange[500],
        },
        userInfo: {
            width: 'calc(100% - 75px)',
        },
        pointer: {
            cursor: 'pointer',
        },
        userName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            fontSize: 15,
            fontWeight: Fonts.MEDIUM,
            color: (props: { bgType: string }) =>
                    props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
        },
    };
});

interface HorUserInfoProps {
    bgType?: string;
}

const HorUserInfo: FC<HorUserInfoProps> = ({bgType = 'colored'}) => {
    const dispatch = useDispatch();
    const user = useAuthUser();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles({bgType});

    return (
            <Box py={2} pl={{xs: 2, sm: 3, md: 5}}>
                <Box className={classes.userRoot} display='flex' onClick={handleClick}>
                    <Hidden mdDown>
                        <Box ml={3} className={classes.userName}>
                            {user && (user.displayName ? user.displayName : '')}
                            <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                                System Manager
                            </Box>
                        </Box>
                    </Hidden>
                </Box>
                <Box className={classes.userInfo}>
                    <Menu
                            id='simple-menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                        <MenuItem>My account</MenuItem>
                        <MenuItem onClick={() => dispatch(onSignout())}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
    );
};

export default HorUserInfo;
